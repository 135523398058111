import { defineStore } from 'pinia'
import { Interval } from 'luxon'
import Big from 'big.js'
import Vue from 'vue'

import { use_calendar_employees } from './calendar_employees_store'
import { use_smcb_gym } from './smcb_gym_store'
import { use_task_groups } from '@core/entry_point/stores/task_groups_store'

const interval_format = 'ddMMyy'
const interval_separator = '-'

function parse_wage_string(wage) {
  let result
  try {
    result = new Big(wage)
  } catch (error) {
    result = null
  }
  return result
}

function interval_as_key([from, to]) {
  return from.toFormat(interval_format) + interval_separator + to.toFormat(interval_format)
}

function interval_key_as_dt(key) {
  const [start, end] = key.split(interval_separator)

  return [
    Vue.$vl_time.parse_as_local_with_format(start, interval_format).startOf('day'),
    Vue.$vl_time.parse_as_local_with_format(end, interval_format).endOf('day'),
  ]
}

function get_task_wage_portion(log, from, to) {
  if (log.has_fixed_wage) return 'fixed'
  const wage = parse_wage_string(log.wage)
  if (!(wage instanceof Big)) return null

  const task_duration = new Big(Vue.$vl_time.task_minute_count(log)).div(60)
  if (task_duration.eq(0)) return new Big(0)

  const task_duration_in_interval = new Big(Vue.$vl_time.task_minute_count(log, from, to)).div(60)
  const quotient = task_duration_in_interval.div(task_duration)
  return wage.times(quotient)
}

function minute_count(log, from, to) {
  if (log.source === 'absence') return Vue.$absences.get_minute_count(log.obj, from, to)
  if (log.source === 'public_holiday_compensation') return new Big(log.obj.hours).times(60).toNumber()
  return Vue.$vl_time.task_minute_count(log, from, to)
}

function formatted_minute_count(log, from, to) {
  if (log.original_task) {
    const old_minutes = Vue.$vl_time.task_minute_count(log.original_task, from, to)
    const new_minutes = Vue.$vl_time.task_minute_count(log, from, to)
    const diff = new_minutes - old_minutes
    const main = Vue.localisation.format_hour_minute_duration({ minutes: old_minutes, default_duration_unit: 'm' })
    let extra = Vue.localisation.format_hour_minute_duration({ minutes: Math.abs(diff), default_duration_unit: 'm' })
    if (diff === 0) return { main }
    const operator = diff < 0 ? ' - ' : ' + '
    extra = `${operator}${extra}`
    return { main, extra }
  }

  return { main: Vue.localisation.format_hour_minute_duration({ minutes: minute_count(log, from, to), default_duration_unit: 'm' }) }
}

function get_task_group(log) {
  if (log.source === 'absence') {
    return {
      name: Vue.$i18n.t(`calendar.staff.absences.edit.types.${log.obj.type}`),
      color: Vue.$absences.get_color(log.obj.type),
    }
  }

  if (log.source === 'public_holiday_compensation') {
    return {
      name: log.obj.holiday_name,
      color: '#334f68',
    }
  }

  if (!log.task_group_id) {
    const employee = use_calendar_employees().from_id(log.employee_id)

    return {
      name: Vue.$i18n.t('calendar.staff.working_hours.working_hours'),
      color: employee.color || '#829AB1',
    }
  }

  return use_task_groups().task_group_from_id(log.task_group_id)
}

function get_date_range(log) {
  const fr_date = Vue.localisation.format_date(use_smcb_gym().settings, log.start)
  const to_date = Vue.localisation.format_date(use_smcb_gym().settings, log.end)
  if (fr_date === to_date) return fr_date
  return `${fr_date} - ${to_date}`
}

function get_time_range(log) {
  if (log.is_all_day) return Vue.$i18n.t('calendar.all_day')
  if (!log.start || !log.end) return '-'
  const a = Vue.localisation.format_time(use_smcb_gym().settings, log.start)
  const b = Vue.localisation.format_time(use_smcb_gym().settings, log.end)
  return `${a} - ${b}`
}

export const use_work_logs = defineStore('work_logs', {
  state: () => ({
    employee_time_log_by_interval: {},
    time_log_requests: [],
  }),

  actions: {
    update_employee_time_log({ employee_id, log, interval }) {
      Vue.set(this.employee_time_log_by_interval, employee_id, this.employee_time_log_by_interval[employee_id] || {})
      Vue.set(this.employee_time_log_by_interval[employee_id], interval_as_key(interval), log)
    },

    delete_employee_time_log_for_date({ employee_id, date }) {
      Object.entries(this.employee_time_log_by_interval[employee_id]).forEach(x => {
        const dates = x[0].split('-')
        const start_date = Vue.$vl_time.parse_as_local_with_format(dates[0], interval_format)
        const end_date = Vue.$vl_time.parse_as_local_with_format(dates[1], interval_format)
        if (start_date.ts <= date.ts && date.ts <= end_date.ts) {
          Vue.delete(this.employee_time_log_by_interval[employee_id], x[0])
        }
      })
    },

    add_work_log({ employee_id, work_log }) {
      const intervals = this.employee_time_log_by_interval[employee_id]
      const work_log_interval = Interval.fromDateTimes(work_log.start, work_log.end)

      for (const interval_key of Object.keys(intervals)) {
        const [start, end] = interval_key_as_dt(interval_key)
        const interval = Interval.fromDateTimes(start, end)

        if (interval.overlaps(work_log_interval)) {
          const work_logs = intervals[interval_key].work_logs || []
          work_logs.push(work_log)
          Vue.set(this.employee_time_log_by_interval[employee_id][interval_key], 'work_logs', work_logs)
        }
      }
    },

    set_work_log({ employee_id, work_log }) {
      const intervals = this.employee_time_log_by_interval[employee_id]
      const work_log_interval = Interval.fromDateTimes(work_log.start, work_log.end)

      for (const interval_key of Object.keys(intervals)) {
        const [start, end] = interval_key_as_dt(interval_key)
        const interval = Interval.fromDateTimes(start, end)

        if (interval.overlaps(work_log_interval)) {
          const work_logs = intervals[interval_key].work_logs.filter(wl => wl.id !== work_log.id)
          work_logs.push(work_log)
          Vue.set(this.employee_time_log_by_interval[employee_id][interval_key], 'work_logs', work_logs)
        }
      }
    },

    remove_work_log({ employee_id, work_log_id }) {
      for (const [interval_key, time_log] of Object.entries(this.employee_time_log_by_interval[employee_id])) {
        const work_logs = time_log.work_logs.filter(wl => wl.id !== work_log_id)
        Vue.set(this.employee_time_log_by_interval[employee_id][interval_key], 'work_logs', work_logs)
      }
    },

    async update_work_log({ work_log }) {
      let url = use_calendar_employees().url_of_existing_employee(work_log.employee_id)
      url = `${url}/time_log/${work_log.id}`
      const { data } = await Vue.smcb_axios.patch(url, { work_log })

      data.start = Vue.$vl_time.parse_as_local(data.start)
      data.end = Vue.$vl_time.parse_as_local(data.end)

      this.set_work_log({ employee_id: data.employee_id, work_log: data })

      return data
    },

    async create_work_log({ work_log }) {
      let url = use_calendar_employees().url_of_existing_employee(work_log.employee_id)
      url = `${url}/time_log`
      const { data } = await Vue.smcb_axios.post(url, { work_log })

      data.start = Vue.$vl_time.parse_as_local(data.start)
      data.end = Vue.$vl_time.parse_as_local(data.end)

      this.add_work_log({ employee_id: data.employee_id, work_log: data })

      return data
    },

    async delete_work_log({ employee_id, work_log_id }) {
      let url = use_calendar_employees().url_of_existing_employee(employee_id)
      url = `${url}/time_log/${work_log_id}`
      const { data } = await Vue.smcb_axios.delete(url)
      this.remove_work_log({ employee_id, work_log_id })
      return data
    },

    cancel_running_time_log_requests() {
      this.time_log_requests.forEach(request => request.abort())
      this.time_log_requests = []
    },

    load_time_logs({ employee_id, intervals }) {
      return Promise.all(
        intervals.map(async ([from, to]) => {
          const { data } = await Vue.smcb_axios.get(`${use_calendar_employees().url_of_existing_employee(employee_id)}/time_log`, {
            params: {
              from: from.toISO(),
              to: to.toISO(),
            },
            before(request) {
              this.time_log_requests.push(request)
            },
          })

          data.assignments = data.assignments.map(assignment => ({
            ...assignment,
            start: Vue.$vl_time.parse_as_local(assignment.task_start),
            end: Vue.$vl_time.parse_as_local(assignment.task_end),
            is_all_day: assignment.task_is_all_day,
          }))

          data.work_logs = data.work_logs.map(work_log => ({
            ...work_log,
            start: Vue.$vl_time.parse_as_local(work_log.start),
            end: Vue.$vl_time.parse_as_local(work_log.end),
          }))

          data.absences = data.absences.map(absence => ({
            ...Vue.$absences.parse(absence),
            start: Vue.$vl_time.parse_as_local_day(absence.start_date),
            end: Vue.$vl_time.parse_as_local_day(absence.end_date),
          }))

          use_calendar_employees().update_employee_fixed_wages({ employee_id, fixed_wages: data.fixed_wages })
          this.update_employee_time_log({ employee_id, log: data, interval: [from, to] })

          this.time_log_requests.splice(
            this.time_log_requests.findIndex(r => r.url === data.url),
            1
          )
        })
      )
    },

    async fetch_employee_time_log({ employee_id, intervals }) {
      const remaining_intervals = []

      for (const interval of intervals) {
        if (!this.employee_time_log_by_interval[employee_id] || !this.employee_time_log_by_interval[employee_id][interval_as_key(interval)]) {
          remaining_intervals.push(interval)
        }
      }

      if (remaining_intervals.length > 0) {
        this.cancel_running_time_log_requests()
        await this.load_time_logs({ employee_id, intervals: remaining_intervals })
      }

      return intervals.map(itv => this.employee_time_log_by_interval[employee_id][interval_as_key(itv)])
    },
  },

  getters: {
    get_employee_merged_work_logs: state => (employee_id, interval) => {
      const data = state.employee_time_log_by_interval[employee_id] && state.employee_time_log_by_interval[employee_id][interval_as_key(interval)]
      if (!data) return null

      const f_task_id = wl => wl.task_id
      const overridden_task_ids = data.work_logs
        .filter(f_task_id)
        .map(f_task_id)
        .reduce((r, id) => ({ ...r, [id]: 1 }), {})

      const overridden_tasks = data.assignments
        .filter(assignment => overridden_task_ids[assignment.task_id])
        .reduce((r, assignment) => ({ ...r, [assignment.task_id]: assignment }), {})

      let merged_logs = data.assignments
        .filter(assignment => !overridden_task_ids[assignment.task_id])
        .map(assignment => {
          const log = {
            source: 'task',
            task_group_id: assignment.task_group_id,
            start: Vue.$vl_time.parse_as_local(assignment.start),
            end: Vue.$vl_time.parse_as_local(assignment.end),
            is_all_day: assignment.is_all_day,
            obj: assignment,
            has_fixed_wage: assignment.has_fixed_wage,
            wage: assignment.wage,
          }

          log.wage = get_task_wage_portion(log, interval[0], interval[1])
          return log
        })

      merged_logs = merged_logs.concat(
        data.work_logs.map(work_log => {
          const log = {
            source: 'work_log',
            task_group_id: work_log.task_group_id,
            work_log_id: work_log.id,
            employee_id: work_log.employee_id,
            note: work_log.note,
            start: Vue.$vl_time.parse_as_local(work_log.start),
            end: Vue.$vl_time.parse_as_local(work_log.end),
            obj: work_log,
            original_task: overridden_tasks[work_log.task_id] || null,
            has_fixed_wage: work_log.has_fixed_wage,
            wage: work_log.wage,
          }

          log.wage = get_task_wage_portion(log, interval[0], interval[1])
          return log
        })
      )

      merged_logs = merged_logs.concat(
        data.working_hours_logs.map(log => ({
          source: 'working_hours_log',
          task_group_id: log.task_group_id,
          employee_id: log.employee_id,
          start: Vue.$vl_time.parse_as_local(log.start),
          end: Vue.$vl_time.parse_as_local(log.end),
          obj: log,
          has_fixed_wage: true,
        }))
      )

      merged_logs = merged_logs.concat(
        data.absences.map(absence => ({
          source: 'absence',
          employee_id: absence.employee_id,
          start: absence.start,
          end: absence.end,
          obj: absence,
          is_all_day: true,
          has_fixed_wage: absence.has_fixed_wage,
          compensation: absence.has_fixed_wage ? new Big(0) : Vue.$absences.get_compensation(absence, interval[0], interval[1]),
        }))
      )

      merged_logs = merged_logs.concat(
        data.public_holiday_compensations.map(compensation => ({
          source: 'public_holiday_compensation',
          employee_id: compensation.employee_id,
          start: Vue.$vl_time.parse_as_local(compensation.holiday_date),
          end: Vue.$vl_time.parse_as_local(compensation.holiday_date),
          obj: compensation,
          is_all_day: true,
          has_fixed_wage: compensation.hourly_compensation == null,
          wage: compensation.hourly_compensation == null ? 'fixed' : new Big(compensation.hourly_compensation).times(new Big(compensation.hours)),
        }))
      )

      merged_logs = merged_logs.map(log => ({
        ...log,
        date_range: get_date_range(log),
        time_range: get_time_range(log),
        task_group: get_task_group(log),
        minute_count: minute_count(log, interval[0], interval[1]),
        formatted_minute_count: formatted_minute_count(log, interval[0], interval[1]),
      }))

      merged_logs.sort((a, b) => (a.start < b.start ? -1 : 1))
      return merged_logs
    },
  },
})
