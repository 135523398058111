import { DateTime } from 'luxon'

//
// DEPRECATED:
//
// Please use this.$localisation or Vue.$localisation from now on
//

const localisationMixin = {
  methods: {
    dateTemplate(settings) {
      switch (settings.date_format) {
        case 'MDY':
          return 'MM/dd/yyyy'
        case 'YMD':
          return 'yyyy/MM/dd'
        case 'DMY':
        default:
          return 'dd/MM/yyyy'
      }
    },

    timeTemplate(settings) {
      switch (settings.time_format) {
        case '12':
          return 'hh:mm a'
        case '24':
        default:
          return 'HH:mm'
      }
    },

    formatDate(settings, dateTime, separator = '/') {
      if (!dateTime) return ''
      if (DateTime.isDateTime(dateTime)) {
        return dateTime.toFormat(this.dateTemplate(settings))
      } else {
        console.warn('Using deprecated date obj in formatDate()', dateTime)
      }

      // TODO: Deprecated, remove all calls using native Date object
      let dateObj = typeof dateTime === 'object' ? dateTime : new Date(dateTime)
      switch (settings.date_format) {
        case 'MDY':
          return dd(dateObj.getMonth() + 1) + separator + dd(dateObj.getDate()) + separator + dateObj.getFullYear()
        case 'YMD':
          return dateObj.getFullYear() + separator + dd(dateObj.getMonth() + 1) + separator + dd(dateObj.getDate())
        case 'DMY':
        default:
          return dd(dateObj.getDate()) + separator + dd(dateObj.getMonth() + 1) + separator + dateObj.getFullYear()
      }
    },

    formatTime(settings, dateTime) {
      if (!dateTime) return ''
      if (DateTime.isDateTime(dateTime)) {
        return dateTime.toFormat(this.timeTemplate(settings))
      } else {
        console.warn('Using deprecated date obj in formatTime()', dateTime)
      }

      // TODO: Deprecated, remove all calls using native Date object
      let dateObj = typeof dateTime === 'object' ? dateTime : new Date(dateTime)
      switch (settings.time_format) {
        case '24':
          return dd(dateObj.getHours()) + ':' + dd(dateObj.getMinutes())
        case '12':
          let hours = dateObj.getHours()
          let suffix = hours < 12 ? 'AM' : 'PM'
          hours = hours === 0 ? 12 : dd(hours)
          return hours + ':' + dd(dateObj.getMinutes()) + ' ' + suffix
        default:
          return dd(dateObj.getHours()) + ':' + dd(dateObj.getMinutes())
      }
    },

    formatDateTime(settings, dateTime) {
      return this.formatDate(settings, dateTime) + ' ' + this.formatTime(settings, dateTime)
    },

    format_weekday_date(settings, dt) {
      const date = this.formatDate(settings, dt)
      if (!date) return null

      // Luxon's DateTime.weekday is cached, and mutates the object itself, raising a Vuex error.
      const cloned = dt.plus(0)

      const weekday = this.$t(`abbrev_day_names.${cloned.weekday % 7}`)
      return `${weekday}, ${date}`
    },

    format_date_range(settings, start, end, is_all_day) {
      const fr_date = this.format_weekday_date(settings, start)
      const to_date = this.format_weekday_date(settings, end)
      const fr_time = this.formatTime(settings, start)
      const to_time = this.formatTime(settings, end)

      if (is_all_day) {
        if (fr_date === to_date) return fr_date
        return `${fr_date} - ${to_date}`
      }

      if (fr_date === to_date) return `${fr_date}, ${fr_time} - ${to_time}`
      return `${fr_date}, ${fr_time} - ${to_date}, ${to_time}`
    },

    format_price(settings, amount, currencyAsText = false, precision = 2) {
      const negativeSign = amount < 0 ? '-' : ''
      const delimiter = (settings.currency_format && settings.currency_format.delimiter) || '.'
      const separator = (settings.currency_format && settings.currency_format.separator) || ','
      const currencySign = (settings.currency_format && settings.currency_format.sign) || '€'
      const currencyText = (settings.currency_format && settings.currency_format.text) || 'EUR'

      let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(precision))).toString()
      let j = i.length > 3 ? i.length % 3 : 0

      return (
        (currencyAsText ? '' : currencySign + ' ') +
        negativeSign +
        (j ? i.substr(0, j) + delimiter : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + delimiter) +
        (precision
          ? separator +
            Math.abs(amount - i)
              .toFixed(precision)
              .slice(2)
          : '') +
        (currencyAsText ? ' ' + currencyText : '')
      )
    },

    format_decimal_number(settings, dec, precision = 0) {
      const negativeSign = dec < 0 ? '-' : ''
      const delimiter = settings.currency_delimiter || '.'
      const separator = settings.currency_separator || ','

      let i = parseInt((dec = Math.abs(Number(dec) || 0).toFixed(precision))).toString()
      let j = i.length > 3 ? i.length % 3 : 0
      return (
        negativeSign +
        (j ? i.substr(0, j) + delimiter : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + delimiter) +
        (precision
          ? separator +
            Math.abs(dec - i)
              .toFixed(precision)
              .slice(2)
          : '')
      )
    },

    last_x_months(count, from, to) {
      if (DateTime.isDateTime(from) && DateTime.isDateTime(to)) {
        let today = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        let monthsAgo = today.minus({ month: count })
        return +to === +today && +from === +monthsAgo
      } else {
        console.warn('Using deprecated date obj in last_x_months()', from, to)

        let today = new Date()
        today.setHours(0, 0, 0, 0)
        let monthsAgo = new Date()
        monthsAgo.setMonth(monthsAgo.getMonth() - count)
        monthsAgo.setHours(0, 0, 0, 0)
        return to.getTime() === today.getTime() && from.getTime() === monthsAgo.getTime()
      }
    },

    get_week_number(dateTime) {
      if (DateTime.isDateTime(dateTime)) {
        // NOTE: Luxon's DateTime.weekNumber caches its value inside the object, which triggers a Vuex
        // "mutation outside of store" error. Before calculating the weekNumber we clone the object.
        return {
          number: DateTime.fromISO(dateTime.toISO()).weekNumber,
          year: dateTime.year,
        }
      } else {
        console.warn('Using deprecated date obj in get_week_number()', dateTime)

        let d = new Date(Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()))
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
        let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
        let weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
        return {
          number: weekNo,
          year: d.getUTCFullYear(),
        }
      }
    },
  },
}

function dd(number) {
  if (number < 10) {
    return `0${number.toString()}`
  } else {
    return number.toString()
  }
}

export default localisationMixin
