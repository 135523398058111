import pricings_lib from '@core/lib/pricings'

export default {
  // TODO [NQ-3255]: check around code
  is_grouped_booking(task) {
    return !!task.recurring_task && !!task.recurring_task.grouped_booking
  },

  flat_rate_pricings(task) {
    const pricings = this.is_grouped_booking(task) ? task.recurring_task.pricings : task.pricings
    return pricings?.filter(pricings_lib.is_flat_rate_pricing) || []
  },

  is_flat_rate_booking(task) {
    return task.ticket_required && this.flat_rate_pricings(task).length > 0
  },

  flat_rate_pricing_booked(task) {
    return this.is_flat_rate_booking(task) && task.booking_participants_count > 0
  },

  flat_rate_label(task) {
    return `${Number(this.flat_rate_pricing_booked(task))}/1`
  },

  show_participants(task) {
    return task.booking_participants_count > 0 || task.max_pax
  },

  participants_label(task) {
    let text = '' + (task.booking_participants_count || 0)
    if (task.max_pax) text += `/${task.max_pax}`
    return text
  },

  employee_count(task) {
    return (task.employee_ids || []).length
  },

  show_employee_label(task) {
    return task.required_employees || (task.participants_per_employee && task.booking_participants_count)
  },

  needed_employees(task) {
    if (task.required_employees) {
      return task.required_employees
    } else if (task.participants_per_employee) {
      return Math.abs(Math.ceil(task.booking_participants_count / task.participants_per_employee - this.employee_count(task)))
    }
  },

  employee_count_label(task) {
    let text = '' + this.employee_count(task)
    text += `/${this.needed_employees(task)}`

    return text
  },

  // The two following methods are required for all_day events because dates are stored with exclusive end date
  // Further information can be found here: https://fullcalendar.io/docs/event-parsing
  populate_ui_end(obj) {
    return obj.is_all_day ? obj.end.minus({ days: 1 }) : obj.end
  },

  full_calendar_end(obj) {
    return obj.is_all_day ? obj.ui_end.plus({ days: 1 }) : obj.ui_end
  },
}
