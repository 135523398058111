import Vue from 'vue'
import { defineStore } from 'pinia'
import { DateTime } from 'luxon'

import { use_calendar_filter } from './calendar_filter_store'
import { use_smcb_gym } from './smcb_gym_store'
import { use_task_groups } from './task_groups_store'
import { use_recurring_tasks } from './recurring_tasks_store'
import { use_payments } from './payments_store'
import editable_mixin from './editable_mixin'
import tasks_lib from '@core/lib/tasks'

function parse_task(task) {
  return Object.freeze({
    ...task,
    start: Vue.$vl_time.parse_as_local(task.start),
    end: Vue.$vl_time.parse_as_local(task.end),
    allDay: task.is_all_day,
    public_from: task.public_from ? Vue.$vl_time.parse_as_local(task.public_from) : null,
    bookable_from: task.bookable_from ? Vue.$vl_time.parse_as_local(task.bookable_from) : null,
    bookable_until: task.bookable_until ? Vue.$vl_time.parse_as_local(task.bookable_until) : null,
  })
}

function parse_booking(booking) {
  return Object.freeze({ ...booking, created_at: Vue.$vl_time.parse_as_local(booking.created_at) })
}

function parse_invitation(invitation) {
  return Object.freeze({ ...invitation, created_at: Vue.$vl_time.parse_as_local(invitation.created_at) })
}

export const use_tasks = defineStore('tasks_store', {
  state: () => ({
    ...editable_mixin.state(),
    all: [],
    in_edit_bookings: [],
    invitations_by_task_id: {},
    loaded_task_urls: [],
    tasks_requests: [],

    is_loading: false,
    is_loading_bookings: false,
    show_dates_reset_warning: false,
    rrule_ref: null,
    rrule_custom_forced: false,
  }),

  actions: {
    ...editable_mixin.actions(),

    // Custom implementation of update_edit to acount for luxon issues on structuredClone
    update_edit(obj) {
      const task_group = use_task_groups().task_group_from_id(obj.task_group_id)

      obj = { ...obj, ui_end: tasks_lib.populate_ui_end(obj), name: obj.name || task_group?.name }

      this.edit_original = structuredClone(obj)
      this.edit = structuredClone(obj)

      this.edit_original.start = obj.start
      this.edit_original.end = obj.end
      this.edit_original.ui_end = obj.ui_end
      this.edit_original.public_from = obj.public_from
      this.edit_original.bookable_from = obj.bookable_from
      this.edit_original.bookable_until = obj.bookable_until

      this.edit.start = obj.start
      this.edit.end = obj.end
      this.edit.ui_end = obj.ui_end
      this.edit.public_from = obj.public_from
      this.edit.bookable_from = obj.bookable_from
      this.edit.bookable_until = obj.bookable_until

      this.is_editing = true
      this.is_saving = false
    },

    unassign_employee({ task_id, employee_id }) {
      const existing_task_index = this.all.findIndex(t => t.id === task_id)
      if (existing_task_index === -1) return
      const task = this.all[existing_task_index]
      const updated_task = { ...task, employee_ids: task.employee_ids.filter(eid => eid !== employee_id) }
      Vue.set(this.all, existing_task_index, updated_task)
    },

    async load_full_task(task_id) {
      const { data } = await Vue.smcb_axios.get(`${this.task_api}/${task_id}`)
      let task = data
      task.employee_ids = task.employee_ids.sort()

      if (task.recurring_task_id) {
        const recurring_task = use_recurring_tasks().recurring_task_from_id(task.recurring_task_id)
        if (recurring_task?.grouped_booking && recurring_task?.pricings) {
          const { data } = await Vue.smcb_axios.get(`${this.task_api}/${task.id}/pricings`)
          task.pricings = data
        }
      }

      return parse_task(task)
    },

    async load_and_edit_task(task_id) {
      this.is_loading = true
      const task = await this.load_full_task(task_id)
      this.update_edit(task)

      this.is_loading = false
    },

    async reload_pricings_in_edit() {
      let url
      if (this.edit.id) {
        url = `${this.task_api}/${this.edit.id}/pricings`
      } else {
        url = `${this.task_groups_api}/${this.edit.task_group_id}/pricings`
      }

      const { data } = await Vue.smcb_axios.get(url)
      this.edit.pricings = data
    },

    async update({ task, invitations }) {
      if (task.ticket_required && !use_payments().onboarding_complete) {
        task.pay_later_allowed = true
      }

      const { data } = await Vue.smcb_axios.put(`${this.task_api}/${task.id}`, {
        ...task,
        invitations,
      })

      this.set_task(data)
    },

    async update_dates({ task }) {
      const ix = this.all.findIndex(t => t.id === task.id)
      Vue.set(this.all, ix, task)

      await Vue.smcb_axios.patch(`${this.task_api}/${task.id}/dates`, task)
    },

    async add({ task, invitations }) {
      if (task.ticket_required && !use_payments().onboarding_complete) {
        task.pay_later_allowed = true
      }

      const { data } = await Vue.smcb_axios.post(`${this.task_api}`, { ...task, invitations })

      this.set_task(data)
      return data.id
    },

    add_tasks(tasks) {
      const old_tasks = this.all
      const new_tasks = tasks.filter(t => !old_tasks.some(u => u.id === t.id)).map(parse_task)
      this.all.push(...new_tasks)
    },

    async delete(task_id) {
      await Vue.smcb_axios.delete(`${this.task_api}/${task_id}`)
      this.all = this.all.filter(t => t.id !== task_id)
    },

    set_task(task) {
      const existing_task_index = this.all.findIndex(t => t.id === task.id)

      if (existing_task_index > -1) {
        Vue.set(this.all, existing_task_index, parse_task(task))
      } else {
        this.all.push(parse_task(task))
      }
    },

    add_update_task(task) {
      const existing_task_index = this.all.findIndex(t => t.id === task.id)

      if (existing_task_index > -1) {
        Vue.set(this.all, existing_task_index, parse_task(task))
      } else {
        this.all.push(parse_task(task))
      }
    },

    async participants_count_for_task(task_id) {
      const url = `${use_tasks().task_api}/${task_id}/participants-count`
      const { data } = await Vue.smcb_axios.get(url)

      Object.entries(data).forEach(([k, v]) => {
        const existing_task_index = this.all.findIndex(t => t.id === parseInt(k))
        const task = this.all[existing_task_index]
        const updated_task = { ...task, booking_participants_count: v }
        Vue.set(this.all, existing_task_index, updated_task)
      })
    },

    async load_tasks_between({ start, end }) {
      const calendar_filter = use_calendar_filter()
      const payload = {
        from_date: start.toFormat('dd-MM-yyyy'),
        to_date: end.toFormat('dd-MM-yyyy'),
        public: calendar_filter.show_only_public,
        open: calendar_filter.show_only_open,
        unpublished: calendar_filter.show_only_unpublished,
        task_groups: calendar_filter.active_task_groups,
        employee_ids: calendar_filter.employee_ids,
      }

      const { data } = await Vue.smcb_axios.post(`${use_smcb_gym().base_url}/full/tasks/between`, payload)
      return data.map(parse_task)
    },

    async fetch_invitations(task_id) {
      const { data } = await Vue.smcb_axios.get(`${this.task_api}/${task_id}/invitations`)
      const invitations = data.map(parse_invitation)
      Vue.set(this.invitations_by_task_id, task_id, invitations)
    },

    async fetch_communications(task_id) {
      const { data } = await Vue.smcb_axios.get(`${this.task_api}/${task_id}/emails`)
      return data
    },

    async send_email_now(task_id, parameters) {
      const { data } = await Vue.smcb_axios.post(`${this.task_api}/${task_id}/send_email`, { ...parameters })
      return data
    },

    async load_selectable_employees({ date, task_id, task_group_id }) {
      const { data } = await Vue.smcb_axios.get(`${use_smcb_gym().base_url}/employees/employment`, { params: { date, task_id, task_group_id } })
      return data
    },

    async load_bookings(task_id) {
      this.is_loading_bookings = true
      this.in_edit_bookings = []

      const { data } = await Vue.smcb_axios.get(`${this.task_api}/${task_id}/bookings`)

      this.in_edit_bookings = data.map(parse_booking)
      this.is_loading_bookings = false
    },

    add_booking(booking) {
      this.in_edit_bookings.push(parse_booking(booking))
    },

    async add_employee_to_task({ task_id, employee_id }) {
      const { data } = await Vue.smcb_axios.post(`${this.task_api}/${task_id}/employees`, { ids: [employee_id] })
      const task = data
      this.add_update_task(task)
      this.update_edit(parse_task(task))
    },

    async remove_employee_from_task({ task_id, employee_id }) {
      const { data } = await Vue.smcb_axios.post(`${this.task_api}/${task_id}/employees/unassign`, { ids: [employee_id] })
      const task = data
      this.add_update_task(task)
      this.update_edit(parse_task(task))
    },

    update_in_edit_booking(booking) {
      Vue.set(
        this.in_edit_bookings,
        this.in_edit_bookings.findIndex(b => b.id === booking.id),
        parse_booking(booking)
      )
    },

    cancel_running_tasks_requests() {
      this.tasks_requests.forEach(request => request.abort())
      this.tasks_requests = []
    },

    remove_tasks_request(url) {
      this.tasks_requests.splice(
        this.tasks_requests.findIndex(r => r.url === url),
        1
      )
    },

    async load_tasks({ start, end }) {
      let urls = new Set()

      for (let i = 0; start.plus({ months: i }).startOf('month') < end; i++) {
        let month = start.plus({ months: i })
        let url = `${use_smcb_gym().base_url}/full/tasks/${month.year}/${month.month}`

        if (!this.loaded_task_urls.includes(url)) {
          this.loaded_task_urls.push(url)
          urls.add(url)
        }
      }

      if (urls.size > 0) {
        // cancel previous requests if any
        this.cancel_running_tasks_requests

        return Promise.all(
          [...urls].map(url => {
            return Vue.smcb_axios
              .get(url, {
                before(request) {
                  // add the request to store so it can be canceled if necessary
                  this.tasks_requests.push(request)
                },
              })
              .then(response => {
                let data = response.data
                this.add_tasks(data.tasks || [])

                use_recurring_tasks().add_recurring_tasks(data.recurring_tasks || [])
                this.remove_tasks_request(response.url)
              })
              .catch(error => {
                // if the request is a canceled one, the status will be 0 so no error needs to be thrown
                if (error.status !== 0) throw error
              })
          })
        )
      } else {
        return Promise.resolve()
      }
    },

    remove_task(taskId) {
      this.all = this.all.filter(t => t.id !== taskId)
    },

    remove_many_tasks(taskIds) {
      this.all = this.all.filter(t => !taskIds.includes(t.id))
    },

    on_task_group_change(task_group) {
      const tg = task_group

      Vue.set(this.edit, 'public', !!tg.public_by_default)

      // Info Tab
      this.edit.name = tg.name
      this.edit.public_description = null
      this.edit.description = null

      // Recurring
      Vue.set(this.edit, 'recurring_task_id', tg.rrule != null ? -1 : null)
      Vue.set(this.edit, 'is_rrule_valid', false)

      // Staff
      this.edit.required_employees = tg.required_employees

      if (this.edit.is_course) {
        // Info Tab
        this.edit.course_level = tg.course_level
        this.edit.max_age = tg.max_age
        this.edit.max_pax = tg.max_pax
        this.edit.min_age = tg.min_age
        this.edit.min_pax = tg.min_pax

        // Recurring
        Vue.set(this.edit, 'grouped_booking', tg.grouped_booking)

        // Registration deadline
        if (tg.bookable_until) {
          Vue.set(this.edit, 'bookable_until', this.edit.start.minus({ days: tg.bookable_until }))
        } else {
          Vue.set(this.edit, 'bookable_until', null)
        }

        // Staff
        this.edit.participants_per_employee = tg.participants_per_employee
        this.edit.show_staff_public = tg.show_staff_public

        if (!this.edit.is_all_day) {
          Vue.set(this.edit, 'preparation_minutes', tg.preparation_minutes)
          Vue.set(this.edit, 'follow_up_minutes', tg.follow_up_minutes)
        }

        // Tickets Tab
        Vue.set(this.edit, 'ticket_required', !!tg.ticket_required)
        Vue.set(this.edit, 'pay_later_allowed', !!tg.pay_later_allowed)
      }
    },
  },

  getters: {
    task_api: () => `${use_smcb_gym().base_url}/tasks`,
    task_groups_api: () => `${use_smcb_gym().base_url}/taskgroups`,
    from_id: state => id => state.all.find(t => t.id === id),

    // TODO [NQ-3255]: fix active to events
    active(state) {
      return state.all
        .filter(t => !t.archived_at && !t.is_course)
        .map(task => {
          const task_group = use_task_groups().task_group_from_id(task.task_group_id)
          return { ...task, title: task.name || task_group?.name || '' }
        })
    },

    courses(state) {
      return state.all
        .filter(t => !t.archived_at && t.is_course)
        .map(task => {
          const task_group = use_task_groups().task_group_from_id(task.task_group_id)
          return { ...task, title: task.name || task_group?.name || '' }
        })
    },

    task_digest:
      state =>
      (task = null) => {
        const task_copy = JSON.parse(JSON.stringify(task || state.edit))

        const task_to_digest = {
          ...task_copy,
          ...(task_copy.min_pax === null && { min_pax: 0 }),
          ...(task_copy.ticket_required === null && { ticket_required: false }),
          ...(task_copy.open_to_bookings === null && { open_to_bookings: false }),
          ...(task_copy.pay_later_allowed === null && { pay_later_allowed: false }),
          pricings: null,
          bookings: null,
        }

        return Object.values(task_to_digest).join()
      },

    has_updated_fields: state => {
      const edit = state.edit && JSON.stringify(Object.fromEntries(Object.entries(state.edit).sort()))
      const edit_original = state.edit_original && JSON.stringify(Object.fromEntries(Object.entries(state.edit_original).sort()))
      return edit !== edit_original
    },

    employee_ids_in_use(state) {
      let employee_ids = new Set()
      state.all
        .filter(task => !task.archived_at)
        .filter(task => {
          let taskGroup = use_task_groups().task_group_from_id(task.task_group_id)
          return taskGroup && taskGroup.show_on_calendar
        })
        .forEach(task => task.employee_ids.forEach(id => employee_ids.add(id)))
      return [...employee_ids]
    },

    task_groups_in_use(state) {
      return state.all
        .filter(task => !task.archived_at)
        .filter(task => {
          let taskGroup = use_task_groups().task_group_from_id(task.task_group_id)
          return taskGroup && taskGroup.show_on_calendar
        })
        .reduce((r, task) => ({ ...r, [task.task_group_id]: true }), {})
    },

    future_tasks_for_employee: state => employee => {
      let now = DateTime.local()
      return state.all.filter(t => t.employee_ids.includes(employee.id) && t.start > now)
    },

    // TODO: Move somewhere more appropriate
    latest_assignment_label: () => (latest_assignment, i18n) => {
      if (!latest_assignment || !latest_assignment.assignment) return '-'
      const end = Vue.$vl_time.parse_as_local(latest_assignment.assignment.end)
      const days = Vue.$vl_time.get_today().diff(end, 'days').toObject().days

      if (days <= 7) {
        return i18n.t('staff.latest_tasks.last_week')
      } else if (days <= 31) {
        return i18n.t('staff.latest_tasks.last_month')
      } else if (days <= 365) {
        return i18n.t('staff.latest_tasks.last_year')
      } else {
        return i18n.t('staff.latest_tasks.more_than_a_year')
      }
    },

    is_new(state) {
      return !state.edit.id
    },

    is_recurring(state) {
      return !!state.edit.recurring_task_id
    },

    is_grouped_booking(state) {
      return use_recurring_tasks().recurring_task_from_id(state.edit.recurring_task_id).grouped_booking
    },
  },
})
